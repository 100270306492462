html {
  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  /* This controls body bg color */
  background-color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100% !important;
}

.App-logo-container {
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0 ;
  margin: 0;
  width: 100%;
}

.App-logo {
  height: 100%;
  pointer-events: none;
}

.page-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  background-color: transparent;
}

.content-wrap {
  width: 100%;
  flex: 1;
  background-color: transparent;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 40px;
}

/* Footer styling */
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 20px 10px;
  background-color: #5c5c5c;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 1000px;
  width: 90%;

  p.legal-line {
    color: rgb(196, 196, 196);
    font-size: 0.8rem
  }
}

/* Signup */
.signup-checkbox-label {
  font-size: 0.8rem;

  a {
    color: #24a540;
    text-decoration: none;
  }
}



.external-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}


.click-out-icon {
  margin-left: 4px;
}

.tab-buttons {
  display: flex;
  margin-bottom: 10px;
}

.tab-buttons button {
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: #000000;
  color: white;
  border: none;
  border-bottom: 2px solid transparent;
}

.tab-buttons button:hover,
.tab-buttons button:focus {
  color: #24a540;
  border-bottom: 2px solid #24a540;
}

.tab-buttons button.active {
  color: #24a540;
  border-bottom: 2px solid #24a540;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ddd;
}


.no-wallets-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* margin: 20px 0; */
  border-width: 2px;
  border: 2px dotted #105e09;
}


.wallet-display-headers-only-mobile {
  display: none !important;
}

.smaller-on-mobile {
  font-size: 1.15rem !important;
  padding: 8px !important;
  width: 100%;
  justify-content: space-between !important;
}

.align-self-center {
  align-self: center;
  justify-self: center;
}

@media screen and (max-width: 600px) {

  .App-logo-container {
    height: 15vh;
  }
  
  .footer-content {
    flex-direction: column;
  }
  .container-frame {
    max-width: 90%;
  }

  .column-on-mobile {
    display: flex;
    flex-direction: column !important;
  }

  .smaller-on-mobile {
    margin: 0 !important;
    font-size: 0.9rem !important;
    padding: 4px !important;
    width: 100%;
    justify-content: space-between !important;
  }

  .align-self-center {
    align-self: flex-start;
    justify-self: center;
  }


}